<footer class="footer_private">
  <div>
    <nav>
      <button class="footerBtn">{{ 'FOOTER.GO_TO_PUBLIC' | translate }}</button>
    </nav>
  </div>
  <div>
    <small class="footerCopyright">{{ 'FOOTER.COPYRIGHT' | translate }}</small>
  </div>
</footer>
