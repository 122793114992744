<div class="mainContainer">
    <div class="errorContainer">
        <header>
            <div class="errorHeader">
                <div class="col_12 col_lg_8">
                <a class="errorHeaderLogoCont">
                <img
                    src="../../../assets/images/logos/tspLogo.png"
                    class="errorHeaderLogo"
                    alt="M&A Consulting & Growth Consulting in Chicago - tsp"
                />
                </a>
            </div>
            </div>
        </header>
        
        <div class="errorTitles">
            <div class="col_12 col_lg_8 errorTextCont">
                <span class="subTitle">404</span>
            <span class="mainTitle">Page Not Found</span>
            </div>

        </div>
        <div class="errorContent">
            <div class="col_12 col_lg_8 errorContentContainer">
                <span class="col_12 col_lg_6 errorMsg">The page you’re looking for was moved, removed, renamed or might’ve never existed.</span>
                <div class="errorButtons">
                    <button class="button" (click)="goToPage('private/home')">
                        Go Home
                    </button>
                </div>
            </div>
        </div>

    </div>
    <footer class="errorFooter">
        <span class="col_12 col_lg_8 footerText">© 2021 tsp Advisors. All Rights Reserved.</span>
    </footer>
</div>