import { Component } from '@angular/core';

@Component({
  selector: 'cad-display-spinner',
  templateUrl: './display-spinner.component.html',
  styleUrls: ['./display-spinner.component.scss']
})
export class DisplaySpinnerComponent {

}
