<table class="table tsp-font-size-default-content">
    <thead>
        <tr style="text-align: center">
            <th scope="col" style="width: 25%">SUBTOTAL</th>
            <th scope="col" style="width: 25%">IGV</th>
            <th scope="col" style="width: 25%">ICBPER</th>
            <th scope="col" style="width: 25%">TOTAL</th>
        </tr>
    </thead>
    <tbody style="text-align: center">
        <td>{{operationSubTotal}}</td>
        <td>{{operationIgv}}</td>
        <td>{{operationIcbper}}</td>
        <td>{{operationTotal}}</td>
    </tbody>
</table>